import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import axios from "axios";
import { useNavigate } from "react-router-dom"; // Ensure you have react-router-dom installed

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const onFinish = (values) => {
    const data = {
      username: values.username,
      password: values.password,
    };

    setLoading(true);
    const url = `${process.env.REACT_APP_SERVER_URL}/users/login`;

    axios
      .post(url, data)
      .then((response) => {
        setLoading(false);
        navigate("/dashboard");
      })
      .catch((error) => {
        message.error("Login failed. Please check your credentials.");
        console.error("Error logging in:", error);
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <img
        src="/logo.png"
        alt="Logo"
        style={{ marginBottom: "20px", width: "250px" }}
      />
      <Form
        name="login-form"
        initialValues={{ remember: true }}
        onFinish={onFinish}
        style={{ width: "300px" }}
      >
        <Form.Item
          label="Username"
          name="username"
          rules={[{ required: true, message: "Please input your username!" }]}
        >
          <Input autoComplete="off" />
        </Form.Item>

        <Form.Item
          label="Password"
          name="password"
          rules={[{ required: true, message: "Please input your password!" }]}
        >
          <Input.Password autoComplete="off" />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            loading={loading}
            style={{ width: "100%" }}
          >
            Log in
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default Login;
