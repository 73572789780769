// src/pages/MonthlyReports.jsx
import React, { useState } from "react";
import axios from "axios";
import {
  DatePicker,
  Table,
  Button,
  message,
  Card,
  Row,
  Col,
  Typography,
} from "antd";
import * as XLSX from "xlsx";
import { ArrowUpOutlined, ArrowDownOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { MonthPicker } = DatePicker;

const MonthlyReports = () => {
  const [transactions, setTransactions] = useState([]);
  const [reportSummary, setReportSummary] = useState(null);
  const [loading, setLoading] = useState(false);
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const fetchMonthlyReport = async (month, year) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/reports/monthly`,
        {
          params: { month, year },
        }
      );
      setReportSummary(response.data);
      setTransactions(response.data.transactions);
      setLoading(false);
    } catch (error) {
      message.error("Failed to fetch monthly report. Please try again.");
      setLoading(false);
    }
  };

  const handleDateChange = (date) => {
    if (date) {
      const month = date.month() + 1; // month() is zero-indexed
      const year = date.year();
      fetchMonthlyReport(month, year);
    }
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(transactions);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");
    XLSX.writeFile(workbook, "monthly_report.xlsx");
  };

  const columns = [
    {
      title: "Loan ID",
      dataIndex: "loan_id",
      key: "loan_id",
    },
    {
      title: "Transaction Date",
      dataIndex: "transaction_date",
      key: "transaction_date",
      render: (text) => new Date(text).toLocaleDateString(),
    },
    {
      title: "Transaction Type",
      dataIndex: "transaction_type",
      key: "transaction_type",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (text) => `K${parseFloat(text).toFixed(2)}`,
    },
    {
      title: "Balance After Transaction",
      dataIndex: "balance_after_transaction",
      key: "balance_after_transaction",
      render: (text) => `K${parseFloat(text).toFixed(2)}`,
    },
    {
      title: "Payment Method",
      dataIndex: "payment_method",
      key: "payment_method",
    },
    {
      title: "Opening Balance",
      dataIndex: "opening_balance",
      key: "opening_balance",
      render: (text) => `K${parseFloat(text).toFixed(2)}`,
    },
    {
      title: "Closing Balance",
      dataIndex: "closing_balance",
      key: "closing_balance",
      render: (text, record) => {
        const closingBalance = parseFloat(text);
        const openingBalance = parseFloat(record.opening_balance);
        return (
          <span>
            {closingBalance > openingBalance ? (
              <ArrowUpOutlined style={{ color: "green" }} />
            ) : (
              <ArrowDownOutlined style={{ color: "red" }} />
            )}{" "}
            K{closingBalance.toFixed(2)}
          </span>
        );
      },
    },
  ];

  return (
    <div style={{ padding: "20px" }}>
      <Card>
        <Row justify="space-between" align="middle">
          <Col>
            <Title level={2}>Monthly Report</Title>
          </Col>
          <Col>
            <MonthPicker
              onChange={handleDateChange}
              placeholder="Select month"
              format="YYYY-MM"
            />
          </Col>
        </Row>
        {reportSummary && (
          <div style={{ marginTop: "20px" }}>
            <p>
              <p>
                <b>Month:</b> {monthNames[reportSummary.month - 1]}
              </p>
            </p>
            <p>
              <b>Year:</b> {reportSummary.year}
            </p>
            <p>
              <b>Opening Balance:</b> K
              {parseFloat(reportSummary.opening_balance).toFixed(2)}
            </p>
            <p>
              <b>Closing Balance:</b> K
              {parseFloat(reportSummary.closing_balance).toFixed(2)}
            </p>
          </div>
        )}
        <Button
          onClick={exportToExcel}
          style={{ marginBottom: "20px", marginTop: "20px" }}
        >
          Export to Excel
        </Button>
        <Table
          columns={columns}
          dataSource={transactions}
          rowKey="id"
          loading={loading}
          pagination={{
            pageSize: 10,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} Total Transactions`,
          }}
        />
      </Card>
    </div>
  );
};

export default MonthlyReports;
