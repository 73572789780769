import React, { useState } from "react";
import axios from "axios";
import { Form, Input, Select, Button, message, Card, Typography } from "antd";
import { v4 as uuidv4 } from "uuid";

const { Option } = Select;
const { Title } = Typography;

const CreateUser = () => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    const token = localStorage.getItem("token");
    setLoading(true);

    if (!token) {
      message.error("No token found. Please log in again.");
      setLoading(false);
      return;
    }

    const userPayload = {
      username: values.username,
      enabled: true,
      emailVerified: true,
      firstName: values.first_name,
      lastName: values.last_name,
      email: values.email,
      credentials: [
        {
          type: "password",
          value: values.password,
          temporary: false,
        },
      ],
      attributes: {
        position: values.position,
        organization: values.organization,
        nrc: values.nrc,
        cwac: values.cwac,
        phone: values.phone,
        gender: values.gender,
      },
    };

    try {
      const response = await axios.post(
        "http://localhost:8000/create-user",
        userPayload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      message.success(response.data.message);
      form.resetFields();
    } catch (error) {
      if (error.response) {
        console.error("Error creating user:", error.response.data);
        message.error(`Error creating user: ${error.response.data.error}`);
      } else {
        console.error("Error creating user:", error.message);
        message.error(`Error creating user: ${error.message}`);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div style={{ margin: "50px", display: "flex", justifyContent: "center" }}>
      <Card
        style={{
          width: "50%",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
        }}
      >
        <Title level={2} style={{ textAlign: "center" }}>
          Registration Form
        </Title>
        <Form form={form} layout="vertical" onFinish={onFinish}>
          <Form.Item
            label="First Name"
            name="first_name"
            rules={[
              { required: true, message: "Please input your first name!" },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item
            label="Last Name"
            name="last_name"
            rules={[
              { required: true, message: "Please input your last name!" },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item
            label="Gender"
            name="gender"
            rules={[{ required: true, message: "Please select your gender!" }]}
          >
            <Select placeholder="Select your gender">
              <Option value="male">Male</Option>
              <Option value="female">Female</Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Username"
            name="username"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item
            label="Assign Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <Input.Password autoComplete="off" />
          </Form.Item>

          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please enter a valid email!" },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item
            label="Phone"
            name="phone"
            rules={[
              { required: true, message: "Please input your phone number!" },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item
            label="Position"
            name="position"
            rules={[{ required: true, message: "Please input your position!" }]}
          >
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item
            label="Organization"
            name="organization"
            rules={[
              { required: true, message: "Please input your organization!" },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item
            label="NRC Number"
            name="nrc"
            rules={[
              { required: true, message: "Please input your NRC number!" },
              {
                pattern: /^\d{6}\/\d{2}\/\d{1}$/,
                message:
                  "Please enter a valid NRC number in the format ******/**/**",
              },
            ]}
          >
            <Input autoComplete="off" />
          </Form.Item>

          <Form.Item
            label="CWAC"
            name="cwac"
            rules={[{ required: true, message: "Please select your CWAC!" }]}
          >
            <Select placeholder="Select your CWAC">
              <Option value="cwac1">CWAC 1</Option>
              <Option value="Twatasha">Twatasha</Option>
              {/* Add other CWACs as needed */}
            </Select>
          </Form.Item>

          <Form.Item>
            <Button type="primary" htmlType="submit" loading={loading} block>
              Submit
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default CreateUser;
