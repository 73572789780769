import React, { useState, useEffect } from "react";
import axios from "axios";
import { Table, Spin, Alert, Input, Avatar, Button, Tooltip } from "antd";
import {
  UserOutlined,
  EyeOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [originalUsers, setOriginalUsers] = useState([]);
  const [users, setUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchText, setSearchText] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUsers = async () => {
      const token = localStorage.getItem("token");
      if (!token) {
        setError("No token found. Please log in again.");
        setLoading(false);
        return;
      }

      try {
        const response = await axios.get(
          "https://keycloak.zeir.smartregister.org/auth/admin/realms/achieve-stage/users/?enabled=true",
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
        const data = response.data.map((user) => ({
          key: user.id,
          gender: user.attributes?.gender?.[0] || "Not Set",
          username: user.username || "Not Set",
          firstName: user.firstName || "Not Set",
          lastName: user.lastName || "Not Set",
          cwac: user.attributes?.cwac?.[0] || "Not Set",
          position: user.attributes?.position?.[0] || "Not Set",
          phone: user.attributes?.phone?.[0] || "Not Set",
        }));
        setUsers(data);
        setOriginalUsers(response.data);
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const columns = [
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      render: (gender) => (
        <Avatar
          style={{
            backgroundColor:
              gender === "Male"
                ? "#1890ff"
                : gender === "Female"
                ? "#FF00E2"
                : "#d9d9d9",
          }}
          icon={<UserOutlined />}
        />
      ),
    },
    {
      title: "Username",
      dataIndex: "username",
      key: "username",
    },
    {
      title: "First Name",
      dataIndex: "firstName",
      key: "firstName",
    },
    {
      title: "Last Name",
      dataIndex: "lastName",
      key: "lastName",
    },
    {
      title: "CWAC",
      dataIndex: "cwac",
      key: "cwac",
    },
    {
      title: "Position",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Phone",
      dataIndex: "phone",
      key: "phone",
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          <Tooltip title="View">
            <Button
              type="link"
              icon={<EyeOutlined />}
              onClick={() => viewUser(record.key)}
            />
          </Tooltip>
          <Tooltip title="Edit">
            <Button
              type="link"
              icon={<EditOutlined />}
              onClick={() => editUser(record.key)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleSearch = (e) => {
    setSearchText(e.target.value.toLowerCase());
  };

  const filteredUsers = users.filter(
    (user) =>
      user.username.toLowerCase().includes(searchText) ||
      user.firstName.toLowerCase().includes(searchText) ||
      user.lastName.toLowerCase().includes(searchText)
  );

  const viewUser = (id) => {
    const originalUser = originalUsers.find((user) => user.id === id);
    navigate(`/view/${id}`, { state: { user: originalUser } });
  };

  const editUser = (id) => {
    // Handle edit user action
    const originalUser = originalUsers.find((user) => user.id === id);
    navigate(`/edit/${id}`, { state: { user: originalUser } });
  };

  const deleteUser = (id) => {
    // Handle delete user action
    console.log(`Delete user with id: ${id}`);
  };

  return (
    <div style={{ padding: "20px" }}>
      <Input
        placeholder="Search by Name or Username"
        value={searchText}
        onChange={handleSearch}
        style={{ marginBottom: "20px", width: "300px" }}
      />
      {loading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <Spin />
          <p>
            <b style={{ fontWeight: "500" }}>Fetching Users...</b>
          </p>
        </div>
      ) : error ? (
        <Alert message={error} type="error" />
      ) : (
        <Table
          columns={columns}
          dataSource={filteredUsers}
          scroll={{ x: true }}
          pagination={{
            pageSize: itemsPerPage,
            showQuickJumper: true,
            showSizeChanger: true,
            showTotal: (total, range) =>
              `${range[0]}-${range[1]} of ${total} Total Users`,
            onShowSizeChange: (current, size) => setItemsPerPage(size),
            total: filteredUsers.length,
            current: currentPage,
            onChange: (page) => setCurrentPage(page),
          }}
        />
      )}
    </div>
  );
};

export default Home;
