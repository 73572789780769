import { initializeApp } from "firebase/app";
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
//import { getAnalytics } from "firebase/analytics";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyArLMECV0BGzcDq1PUHiOgTk8HBr9qdYQw",
  authDomain: "gpn-loans.firebaseapp.com",
  projectId: "gpn-loans",
  storageBucket: "gpn-loans.appspot.com",
  messagingSenderId: "953301918246",
  appId: "1:953301918246:web:f5520e37f89de34dd1fce7",
  measurementId: "G-KLJ3CN9PV5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Storage
const storage = getStorage(app);
//const analytics = getAnalytics(app);

export { app, storage, ref, uploadBytes, getDownloadURL };
