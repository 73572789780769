import React from "react";
import { Layout as AntdLayout, Button } from "antd";
import Sidebar from "./Sidebar";
import Navbar from "./Navbar";
import { useNavigate } from "react-router-dom";
import { ArrowLeftOutlined } from "@ant-design/icons";

const { Content, Sider } = AntdLayout;

const Layout = ({ children }) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Go back to the previous page
  };

  return (
    <AntdLayout style={{ minHeight: "100vh" }}>
      <Navbar />
      <AntdLayout>
        <Sider width={200} className="site-layout-background">
          <Sidebar />
        </Sider>
        <AntdLayout style={{ padding: "0 24px 24px" }}>
          <div
            style={{ margin: "16px 0", display: "flex", alignItems: "center" }}
          >
            <Button icon={<ArrowLeftOutlined />} onClick={handleBack}>
              Back
            </Button>
          </div>
          <Content
            style={{
              padding: 24,
              margin: 0,
              minHeight: 280,
            }}
          >
            {children}
          </Content>
        </AntdLayout>
      </AntdLayout>
    </AntdLayout>
  );
};

export default Layout;
