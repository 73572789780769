import React, { useState } from "react";
import { Form, Input, Button, Upload, message } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "axios";
import { storage } from "../config/firebase"; // Import the storage from the firebase configuration
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";

const AddDebtor = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const uploadFile = (file) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `images/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        null,
        (error) => reject(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
            resolve(downloadURL);
          });
        }
      );
    });
  };

  const handleFinish = async (values) => {
    try {
      setLoading(true);
      const profilePicUrl = await uploadFile(
        values.profile_pic[0].originFileObj
      );
      const idFrontUrl = await uploadFile(values.id_front[0].originFileObj);
      const idBackUrl = await uploadFile(values.id_back[0].originFileObj);

      const debtorData = {
        first_name: values.first_name,
        last_name: values.last_name,
        email: values.email,
        phone: values.phone,
        address: values.address,
        profile_pic: profilePicUrl,
        id_front: idFrontUrl,
        id_back: idBackUrl,
        next_of_kin: values.next_of_kin,
        next_of_kin_phone: values.next_of_kin_phone,
      };

      await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/debtors`,
        debtorData
      );
      message.success("Debtor added successfully!");
      form.resetFields();
      setLoading(false);
    } catch (error) {
      console.error("Error adding debtor:", error);
      message.error("Failed to add debtor. Please try again.");
      setLoading(false);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      onFinish={handleFinish}
      style={{
        maxWidth: "600px",
        margin: "0 auto",
        padding: "20px",
        border: "1px solid #ccc",
        borderRadius: "10px",
      }}
    >
      <Form.Item
        label="First Name"
        name="first_name"
        rules={[{ required: true, message: "Please input first name!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Last Name"
        name="last_name"
        rules={[{ required: true, message: "Please input last name!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Email"
        name="email"
        rules={[{ required: true, message: "Please input email!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Phone"
        name="phone"
        rules={[{ required: true, message: "Please input phone!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Address"
        name="address"
        rules={[{ required: true, message: "Please input address!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Next of Kin"
        name="next_of_kin"
        rules={[{ required: true, message: "Please input next of kin!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Next of Kin Phone"
        name="next_of_kin_phone"
        rules={[{ required: true, message: "Please input next of kin phone!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Profile Picture"
        name="profile_pic"
        valuePropName="fileList"
        getValueFromEvent={(e) => e.fileList}
        rules={[{ required: true, message: "Please upload profile picture!" }]}
      >
        <Upload listType="picture" maxCount={1} beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Upload Profile Picture</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        label="ID Front"
        name="id_front"
        valuePropName="fileList"
        getValueFromEvent={(e) => e.fileList}
        rules={[{ required: true, message: "Please upload ID front!" }]}
      >
        <Upload listType="picture" maxCount={1} beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Upload ID Front</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        label="ID Back"
        name="id_back"
        valuePropName="fileList"
        getValueFromEvent={(e) => e.fileList}
        rules={[{ required: true, message: "Please upload ID back!" }]}
      >
        <Upload listType="picture" maxCount={1} beforeUpload={() => false}>
          <Button icon={<UploadOutlined />}>Upload ID Back</Button>
        </Upload>
      </Form.Item>
      <Button type="primary" htmlType="submit" loading={loading}>
        Add Debtor
      </Button>
    </Form>
  );
};

export default AddDebtor;
