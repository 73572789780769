import "./App.css";
import CreateUser from "./components/createUser";
import Login from "./components/login";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Layout from "./components/Layout";
import Home from "./components/home";
import Dashboard from "./pages/dashboard";
import SingleUser from "./components/singleUser";
import Detbors from "./pages/debtors";
import SingleDebtor from "./pages/singleDebtor";
import AddDebtor from "./pages/addDebtor";
import Loans from "./pages/loans";
import SingleLoan from "./pages/singleLoan";
import AddLoan from "./pages/addLoan";
import Transactions from "./pages/transactions";
import MonthlyReports from "./pages/monthlyReport";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/dashboard"
          element={
            <Layout>
              <Dashboard />
            </Layout>
          }
        />
        <Route
          path="/home"
          element={
            <Layout>
              <Home />
            </Layout>
          }
        />
        <Route
          path="/createUser"
          element={
            <Layout>
              <CreateUser />
            </Layout>
          }
        />
        //reports/monthly
        <Route
          path="/debtors"
          element={
            <Layout>
              <Detbors />
            </Layout>
          }
        />
        <Route
          path="/reports/monthly"
          element={
            <Layout>
              <MonthlyReports />
            </Layout>
          }
        />
        <Route
          path="/loans"
          element={
            <Layout>
              <Loans />
            </Layout>
          }
        />
        <Route
          path="/transactions"
          element={
            <Layout>
              <Transactions />
            </Layout>
          }
        />
        <Route
          path="/add-debtor"
          element={
            <Layout>
              <AddDebtor />
            </Layout>
          }
        />
        <Route
          path="/add-loan"
          element={
            <Layout>
              <AddLoan />
            </Layout>
          }
        />
        <Route
          path="/debtors/:id"
          element={
            <Layout>
              <SingleDebtor />
            </Layout>
          }
        />
        <Route
          path="/transactions/:id"
          element={
            <Layout>
              <Transactions />
            </Layout>
          }
        />
        <Route
          path="/loans/:id"
          element={
            <Layout>
              <SingleLoan />
            </Layout>
          }
        />
        <Route
          path="/view/:id"
          element={
            <Layout>
              <SingleUser />
            </Layout>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;
