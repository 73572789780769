import React from "react";
import { Card, Descriptions, Avatar, Row, Col } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";

const SingleUser = () => {
  const location = useLocation();
  const { user } = location.state;

  const getValue = (attribute) => {
    return attribute && attribute.length > 0 ? attribute[0] : "Not Set";
  };

  return (
    <Card
      style={{ margin: "20px" }}
      title={
        <Row>
          <Col span={2}>
            <Avatar
              style={{
                marginTop: "20px",
                backgroundColor:
                  getValue(user.attributes.gender) === "Male"
                    ? "#1890ff"
                    : getValue(user.attributes.gender) === "Female"
                    ? "#FF00E2"
                    : "#d9d9d9",
              }}
              size={64}
              icon={<UserOutlined />}
            />
          </Col>
          <Col span={22}>
            <h2>{`${user.firstName} ${user.lastName}`}</h2>
            <p>{user.username}</p>
          </Col>
        </Row>
      }
    >
      <Descriptions bordered column={2}>
        <Descriptions.Item label="First Name">
          {user.firstName || "Not Set"}
        </Descriptions.Item>
        <Descriptions.Item label="Last Name">
          {user.lastName || "Not Set"}
        </Descriptions.Item>
        <Descriptions.Item label="Username">
          {user.username || "Not Set"}
        </Descriptions.Item>
        <Descriptions.Item label="Email">
          {user.email || "Not Set"}
        </Descriptions.Item>
        <Descriptions.Item label="Phone">
          {getValue(user.attributes.phone)}
        </Descriptions.Item>
        <Descriptions.Item label="Position">
          {getValue(user.attributes.position)}
        </Descriptions.Item>
        <Descriptions.Item label="Organization">
          {getValue(user.attributes.organization)}
        </Descriptions.Item>
        <Descriptions.Item label="NRC">
          {getValue(user.attributes.nrc)}
        </Descriptions.Item>
        <Descriptions.Item label="Gender">
          {getValue(user.attributes.gender)}
        </Descriptions.Item>
        <Descriptions.Item label="Province">
          {getValue(user.attributes.province)}
        </Descriptions.Item>
        <Descriptions.Item label="District">
          {getValue(user.attributes.district)}
        </Descriptions.Item>
        <Descriptions.Item label="Constituency">
          {getValue(user.attributes.constituency)}
        </Descriptions.Item>
        <Descriptions.Item label="Ward">
          {getValue(user.attributes.ward)}
        </Descriptions.Item>
        <Descriptions.Item label="CWAC">
          {getValue(user.attributes.cwac)}
        </Descriptions.Item>
        <Descriptions.Item label="ACC">
          {getValue(user.attributes.acc)}
        </Descriptions.Item>
        <Descriptions.Item label="Rural">
          {getValue(user.attributes.rural)}
        </Descriptions.Item>
      </Descriptions>
    </Card>
  );
};

export default SingleUser;
